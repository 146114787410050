import axios from "axios";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Authorization"] = sessionStorage.getItem("token");

export const reloadHeader = () => {
  axios.defaults.headers.post["Authorization"] = sessionStorage.getItem("token");
};

export const getNotifications = (message, next) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `{
                    searchFirebaseNotifications(first: 10, after: "${next}", message_Icontains: "", firebase: false){
                        pageInfo{
                            endCursor
                            hasNextPage
                        }
                        edges{
                            node{
                                id
                                message
                                readClient
                                title
                                reservation {
                                   id
                                    total
                                    email
                                    tipoCobertura
                                    dias
                                    serviciosExtras
                                    tarifaDiaria
                                    comentarios
                                    fechaRenta
                                    fechaFin
                                    nombre
                                    apellidos
                                    telefono
                                    edad
                                    pagoId
                                    origen
                                    revisado
                                    noReserva
                                    fecha
                                    estado
                                    metodoPago
                                    cantidad
                                    categoria {
                                        id
                                        name
                                        tradicionalAlta
                                        totalAlta
                                        tradicionalBaja
                                        totalBaja
                                        isActive
                                        fecha
                                        image
                                        iconoWhite
                                        iconoBlack
                                        maletas
                                        maletasSm
                                        personas
                                        transmision
                                        lugar
                                    }
                                    sucursal {
                                        id
                                        name
                                        phone
                                        contactPhone
                                        address
                                        postalCod
                                        openHour
                                        closeHour
                                        isActive
                                    }
                                    agente {
                                        id
                                        username
                                        firstName
                                        lastName
                                        email
                                        isActive
                                        phone
                                    }
                                }
                            }
                        }
                    }
                }`
    }
  );
};

export const readMessage = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `mutation($id:Int!){
                    readNotification(id: $id){
                        info{
                            id
                            message
                            title
                            readClient
                            reservation {
                                   id
                                    total
                                    email
                                    tipoCobertura
                                    dias
                                    serviciosExtras
                                    tarifaDiaria
                                    comentarios
                                    fechaRenta
                                    fechaFin
                                    nombre
                                    apellidos
                                    telefono
                                    edad
                                    pagoId
                                    origen
                                    revisado
                                    noReserva
                                    fecha
                                    estado
                                    metodoPago
                                    cantidad
                                    categoria {
                                        id
                                        name
                                        tradicionalAlta
                                        totalAlta
                                        tradicionalBaja
                                        totalBaja
                                        isActive
                                        fecha
                                        image
                                        iconoWhite
                                        iconoBlack
                                        maletas
                                        maletasSm
                                        personas
                                        transmision
                                        lugar
                                    }
                                    sucursal {
                                        id
                                        name
                                        phone
                                        contactPhone
                                        address
                                        postalCod
                                        openHour
                                        closeHour
                                        isActive
                                    }
                                    agente {
                                        id
                                        username
                                        firstName
                                        lastName
                                        email
                                        isActive
                                        phone
                                    }
                                }
                        }
                   }
                 }`, variables: obj
    }
  );
};

export const getReservationsNoShow = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchReservations(
    $name: String!
    $before: String!
    $after: String!
    $first: Int!
) {
    searchReservationsNoshow(
        before: $before
        after: $after
        first: $first
        name:$name
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                noShow
                total
                email
                tipoCobertura
                dias
                serviciosExtras
                tarifaDiaria
                comentarios
                fechaRenta
                fechaFin
                nombre
                apellidos
                telefono
                edad
                pagoId
                origen
                revisado
                noReserva
                fecha
                estado
                metodoPago
                cantidad
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    phone
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};
export const getReservationsNoShowNext = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchReservations(
    $name: String!
    $after: String!
    $first: Int!
) {
    searchReservationsNoshow(
        after: $after
        first: $first
        before:""
        name:$name
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                noShow
                total
                email
                tipoCobertura
                dias
                serviciosExtras
                tarifaDiaria
                comentarios
                fechaRenta
                fechaFin
                nombre
                apellidos
                telefono
                edad
                pagoId
                origen
                revisado
                noReserva
                fecha
                estado
                metodoPago
                cantidad
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    phone
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};
export const getReservationsNoShowPrev = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchReservations(
    $name: String!
    $before: String!
    $after: String!
    $first: Int!
) {
    searchReservationsNoshow(
        before: $before
        after: $after
        first: $first
        name:$name
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                noShow
                total
                email
                tipoCobertura
                dias
                serviciosExtras
                tarifaDiaria
                comentarios
                fechaRenta
                fechaFin
                nombre
                apellidos
                telefono
                edad
                pagoId
                origen
                revisado
                noReserva
                fecha
                estado
                metodoPago
                cantidad
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    phone
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};
export const getReservations = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchReservations(
    $name: String!
    $before: String!
    $after: String!
    $first: Int!
    $origen:String!
) {
    searchReservations(
        before: $before
        after: $after
        first: $first
        origen_Icontains: $origen
        name:$name
        noShow: false
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                noShow
                total
                email
                tipoCobertura
                dias
                serviciosExtras
                tarifaDiaria
                comentarios
                fechaRenta
                fechaFin
                nombre
                apellidos
                telefono
                edad
                pagoId
                origen
                revisado
                noReserva
                fecha
                estado
                metodoPago
                cantidad
                extraservicereserveSet {
                    id
                    description
                    price
                    daily
                }
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    phone
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};
export const getReservationsNext = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchReservations(
    $name: String!
    $after: String!
    $first: Int!
    $origen:String!
) {
    searchReservations(
        after: $after
        first: $first
        before:""
        origen_Icontains: $origen
        name:$name
        noShow: false
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                total
                email
                noShow
                tipoCobertura
                dias
                serviciosExtras
                tarifaDiaria
                comentarios
                fechaRenta
                fechaFin
                nombre
                apellidos
                telefono
                edad
                pagoId
                origen
                revisado
                noReserva
                fecha
                estado
                metodoPago
                cantidad
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    phone
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};
export const getReservationsPrev = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchReservations(
    $name: String!
    $before: String!
    $first: Int!
    $origen:String!
) {
    searchReservations(
        before: $before
        first: $first
        after:""
        origen_Icontains: $origen
        name:$name
        noShow: false
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                total
                noShow
                email
                tipoCobertura
                dias
                serviciosExtras
                tarifaDiaria
                comentarios
                fechaRenta
                fechaFin
                nombre
                apellidos
                telefono
                edad
                pagoId
                origen
                revisado
                noReserva
                fecha
                estado
                metodoPago
                cantidad
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    phone
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};
export const CoberturasPerTarifaTotal = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query CoberturasPerTarifaTotal {
            coberturasPerTarifaTotal(name: "") {
                id
                cobertura
                isActive
            }
        }`
    }
  );
};
export const CoberturasPerTarifaTradicional = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query CoberturasPerTarifaTradicional {
    coberturasPerTarifaTradicional(name: "") {
        id
        cobertura
        isActive
    }
}`
    }
  );
};

export const getReservationsById = (id) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query ReservaById($id:ID!) {
    reservaById(id: $id) {
                id
                total
                noShow
                email
                tipoCobertura
                dias
                serviciosExtras
                tarifaDiaria
                comentarios
                fechaRenta
                fechaFin
                nombre
                apellidos
                telefono
                edad
                pagoId
                origen
                revisado
                noReserva
                fecha
                estado
                metodoPago
                cantidad
                categoria {
                    id
                    name
                    tradicionalAlta
                    totalAlta
                    tradicionalBaja
                    totalBaja
                    isActive
                    fecha
                    image
                    iconoWhite
                    iconoBlack
                    maletas
                    maletasSm
                    personas
                    transmision
                    lugar
                }
                sucursal {
                    id
                    name
                    phone
                    contactPhone
                    address
                    postalCod
                    openHour
                    closeHour
                    isActive
                }
                agente {
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    phone
                }
            }
        }`,
      variables: { id: id }
    }
  );
};
export const getNotificationsNotRead = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `{
                    searchFirebaseNotifications(readClient: false, firebase: false){
                        edges{
                            node{
                                id
                                message
                                readClient
                                title
                                reservation {
                                   id
                                    total
                                    email
                                    tipoCobertura
                                    dias
                                    serviciosExtras
                                    tarifaDiaria
                                    comentarios
                                    fechaRenta
                                    fechaFin
                                    nombre
                                    apellidos
                                    telefono
                                    edad
                                    pagoId
                                    origen
                                    revisado
                                    noReserva
                                    fecha
                                    estado
                                    metodoPago
                                    cantidad
                                    categoria {
                                        id
                                        name
                                        tradicionalAlta
                                        totalAlta
                                        tradicionalBaja
                                        totalBaja
                                        isActive
                                        fecha
                                        image
                                        iconoWhite
                                        iconoBlack
                                        maletas
                                        maletasSm
                                        personas
                                        transmision
                                        lugar
                                    }
                                    sucursal {
                                        id
                                        name
                                        phone
                                        contactPhone
                                        address
                                        postalCod
                                        openHour
                                        closeHour
                                        isActive
                                    }
                                    agente {
                                        id
                                        username
                                        firstName
                                        lastName
                                        email
                                        isActive
                                        phone
                                    }
                                }
                            }
                        }
                    }
                }`
    }
  );
};
export const createExtraServiceToReserve = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `mutation CreateServiceToReserve(
                $daily:Boolean!
                $description:String!
                $price:Decimal!
                $reserve:ID!
            ) {
                createServiceToReserve(
                    daily: $daily
                    description: $description
                    price: $price
                    reserve: $reserve
                ) {
                    success
                    error
                }
            }
            `, variables: obj
    }
  );
};
export const createExtraServiceToContract = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `mutation CreateServiceToContract(
                $contract:ID!
                $daily:Boolean!
                $description:String!
                $price:Decimal!
            ) {
                createServiceToContract(
                    contract: $contract
                    daily: $daily
                    description: $description
                    price: $price
                ) {
                    success
                    error
                }
            }`, variables: obj
    }
  );
};
export const serviceByContract = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query ServicioPorContrato(
                $idServicio:Int!
                $idContrato:String!
            ) {
                servicioPorContrato(idServicio: $idServicio, idContrato: $idContrato)
            }`, variables: obj
    }
  );
};
export const servicesPerName = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query ServiciosPerName {
                serviciosPerName(name: "") {
                    id
                    isActive
                    name
                    diario
                    precio
                    icono
                }
            }`
    }
  );
};
export const titulares = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query TitularPerName {
                titularPerName(name: "") {
                    id
                    name
                    isActive
                }
            }`
    }
  );
};


export const getClients = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchClients(
    $name: String!
    $before: String!
    $after: String!
    $first: Int!
) {
    searchClients(
        before: $before
        after: $after
        first: $first
        name:$name
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                nombre
                apellidos
                telefono
                email
                edad
                isActive
                direccion
                postal
                telefonoEmergencia
                carnet
                licenciaFile
                pasaporte
                tipo {
                    id
                    name
                    isActive
                }
                ciudad
                estado
                pais
                licencia {
                    id
                    numero
                    vencimiento
                    permanente
                    estado
                    pais
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};
export const getClientsNext = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchClients(
    $name: String!
    $after: String!
    $first: Int!
) {
    searchClients(
        before: ""
        after: $after
        first: $first
        name:$name
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                nombre
                apellidos
                telefono
                email
                edad
                isActive
                direccion
                postal
                telefonoEmergencia
                carnet
                licenciaFile
                pasaporte
                tipo {
                    id
                    name
                    isActive
                }
                ciudad
                estado
                pais
                licencia {
                    id
                    numero
                    vencimiento
                    permanente
                    estado
                    pais
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};
export const getClientsPrev = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchClients(
    $name: String!
    $before: String!
    $first: Int!
) {
    searchClients(
        before: $before
        after: ""
        first: $first
        name:$name
    ) {
        pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
        }
        edges {
            cursor
            node {
                id
                nombre
                apellidos
                telefono
                email
                edad
                isActive
                direccion
                postal
                telefonoEmergencia
                carnet
                licenciaFile
                pasaporte
                tipo {
                    id
                    name
                    isActive
                }
                ciudad
                estado
                pais
                licencia {
                    id
                    numero
                    vencimiento
                    permanente
                    estado
                    pais
                }
            }
        }
    }
}`,
      variables: obj
    }
  );
};

export const getContractsOpen = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchContratosOpen(
                $name: String!
                $before:String!
                $after:String!
                $first:Int!
                ) {
                searchContractsOpen(
                    name:$name
                    before: $before
                    after: $after
                    first: $first
                    estado_Icontains: "Abierto"
                ) {
                    edges {
                        cursor
                        node {
                           id
                           estado
                            tipoCobertura
                            cortesia {
                                id
                                name
                                descuento
                            }
                            extraservicecontractSet {
                                id
                                description
                                price
                                daily
                            }
                            seguroRc
                            revisado
                            tipoPago
                            fecha
                            precioHoraExtra
                            numContrato
                            fechaSalida
                            total
                            fechaRegreso
                            tarifaDiaria
                            tarifaExtra
                            dias
                            tanque
                            deuda
                            depositoEfectivo
                            checkin
                            credito
                            comentarios
                            canal
                            agente {
                                id
                                username
                                firstName
                                lastName
                                email
                            }
                            cliente {
                                id
                                nombre
                                apellidos
                                direccion
                                telefonoEmergencia
                                telefono
                                email
                                edad
                                licencia {
                                    numero
                                    vencimiento
                                    estado
                                }
                            }
                            vehiculo {
                                id
                                placa
                                noEcon
                                color
                                capacidadTanke
                                version{
                                  name
                                  modelo{
                                    name
                                    marca{
                                      name
                                    }
                                  }
                                }
                                categoria {
                                    id
                                    name
                                    tradicionalAlta
                                    totalAlta
                                    tradicionalBaja
                                    totalBaja
                                }
                            }
                            sucursal {
                                id
                                name
                                phone
                                address
                                postalCod
                                openHour
                                closeHour
                                isActive
                            }
                            conductor {
                                id
                                nombre
                                apellidos
                                edad
                                licencia {
                                    id
                                    numero
                                    vencimiento
                                    permanente
                                    estado
                                }
                                cliente {
                                    id
                                    nombre
                                    apellidos
                                    telefono
                                    email
                                    edad
                                    isActive
                                    direccion
                                    postal
                                    telefonoEmergencia
                                }
                            }
                            tarjeta {
                                id
                                numero
                                vencimiento
                                autorizacion
                            }
                        }
                    }
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                }
            }`,
      variables: obj
    }
  );
};
export const getContractsOpenNext = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchContratosOpen(
                $name: String!
                $before: String!
                $first: Int!
            ) {
                searchContractsOpen(
                    name:$name
                    before: $before
                    after:""
                    first: $first
                    estado_Icontains: "Abierto"
                ) {
                    edges {
                        cursor
                        node {
                            id
                            estado
                            tipoCobertura
                            seguroRc
                            cortesia {
                                id
                                name
                                descuento
                            }
                            extraservicecontractSet {
                                id
                                description
                                price
                                daily
                            }
                            revisado
                            tipoPago
                            fecha
                            precioHoraExtra
                            numContrato
                            fechaSalida
                            total
                            fechaRegreso
                            tarifaDiaria
                            tarifaExtra
                            dias
                            tanque
                            deuda
                            depositoEfectivo
                            checkin
                            credito
                            comentarios
                            canal
                            agente {
                                id
                                username
                                firstName
                                lastName
                                email
                            }
                            cliente {
                                id
                                nombre
                                apellidos
                                direccion
                                telefonoEmergencia
                                telefono
                                email
                                edad
                                licencia {
                                    numero
                                    vencimiento
                                    estado
                                }
                            }
                            vehiculo {
                                id
                                placa
                                noEcon
                                color
                                capacidadTanke
                                version {
                                    name
                                    modelo {
                                        name
                                        marca {
                                            name
                                        }
                                    }
                                }
                                categoria {
                                    id
                                    name
                                    tradicionalAlta
                                    totalAlta
                                    tradicionalBaja
                                    totalBaja
                                }
                            }
                            sucursal {
                                id
                                name
                            }
                            conductor {
                                id
                                nombre
                                apellidos
                                edad
                                licencia {
                                    id
                                    numero
                                    vencimiento
                                    permanente
                                    estado
                                }
                                cliente {
                                    id
                                    nombre
                                    apellidos
                                    telefono
                                    email
                                    edad
                                    isActive
                                    direccion
                                    postal
                                    telefonoEmergencia
                                }
                            }
                            tarjeta {
                                id
                                numero
                                vencimiento
                                autorizacion
                            }
                        }
                    }
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                }
            }`,
      variables: obj
    }
  );
};
export const getContractsOpenPrev = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchContratos(
                $name: String!
                $after:String!
                $first:Int!
                ) {
                searchContractsOpen(
                    name:$name
                    after: $after
                    before:""
                    first: $first
                    estado_Icontains: "Abierto"
                ) {
                    edges {
                        cursor
                        node {
                           id
                           estado
                            tipoCobertura
                            seguroRc
                            revisado
                            tipoPago
                            fecha
                            precioHoraExtra
                            numContrato
                            cortesia {
                                id
                                name
                                descuento
                            }
                            extraservicecontractSet {
                                id
                                description
                                price
                                daily
                            }
                            fechaSalida
                            total
                            fechaRegreso
                            tarifaDiaria
                            tarifaExtra
                            dias
                            tanque
                            deuda
                            depositoEfectivo
                            checkin
                            credito
                            comentarios
                            canal
                            agente {
                                id
                                username
                                firstName
                                lastName
                                email
                            }
                            cliente {
                                id
                                nombre
                                apellidos
                                direccion
                                telefonoEmergencia
                                telefono
                                email
                                edad
                                licencia {
                                    numero
                                    vencimiento
                                    estado
                                }
                            }
                            vehiculo {
                                id
                                placa
                                noEcon
                                color
                                capacidadTanke
                                version{
                                  name
                                  modelo{
                                    name
                                    marca{
                                      name
                                    }
                                  }
                                }
                                categoria {
                                    id
                                    name
                                    tradicionalAlta
                                    totalAlta
                                    tradicionalBaja
                                    totalBaja
                                }
                            }
                            sucursal {
                                id
                                name
                            }
                            conductor {
                                id
                                nombre
                                apellidos
                                edad
                                licencia {
                                    id
                                    numero
                                    vencimiento
                                    permanente
                                    estado
                                }
                                cliente {
                                    id
                                    nombre
                                    apellidos
                                    telefono
                                    email
                                    edad
                                    isActive
                                    direccion
                                    postal
                                    telefonoEmergencia
                                }
                            }
                            tarjeta {
                                id
                                numero
                                vencimiento
                                autorizacion
                            }
                        }
                    }
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                }
            }`,
      variables: obj
    }
  );
};

export const getContracts = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchContratos(
                $name: String!
                $before:String!
                $after:String!
                $first:Int!
                $estado:String!
                ) {
                searchContracts(
                    name:$name
                    before: $before
                    after: $after
                    first: $first
                    estado_Icontains: $estado
                ) {
                    edges {
                        cursor
                        node {
                           id
                           estado
                            tipoCobertura
                            seguroRc
                            revisado
                            tipoPago
                            fecha
                            precioHoraExtra
                            numContrato
                            fechaSalida
                            total
                            fechaRegreso
                            tarifaDiaria
                            tarifaExtra
                            dias
                            tanque
                            deuda
                            depositoEfectivo
                            checkin
                            credito
                            comentarios
                            canal
                            agente {
                                id
                                username
                                firstName
                                lastName
                                email
                            }
                            cliente {
                                id
                                nombre
                                apellidos
                                direccion
                                telefonoEmergencia
                                telefono
                                email
                                edad
                                licencia {
                                    numero
                                    vencimiento
                                    estado
                                }
                            }
                            vehiculo {
                                id
                                placa
                                noEcon
                                color
                                capacidadTanke
                                version{
                                  name
                                  modelo{
                                    name
                                    marca{
                                      name
                                    }
                                  }
                                }
                                categoria {
                                    id
                                    name
                                    tradicionalAlta
                                    totalAlta
                                    tradicionalBaja
                                    totalBaja
                                }
                            }
                            sucursal {
                                id
                                name
                            }
                            conductor {
                                id
                                nombre
                                apellidos
                                edad
                                licencia {
                                    id
                                    numero
                                    vencimiento
                                    permanente
                                    estado
                                }
                                cliente {
                                    id
                                    nombre
                                    apellidos
                                    telefono
                                    email
                                    edad
                                    isActive
                                    direccion
                                    postal
                                    telefonoEmergencia
                                }
                            }
                            tarjeta {
                                id
                                numero
                                vencimiento
                                autorizacion
                            }
                        }
                    }
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                }
            }`,
      variables: obj
    }
  );
};
export const getContractsNext = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchContratos(
                $name: String!
                $after:String!
                $first:Int!
                $estado:String!
                ) {
                searchContracts(
                    name:$name
                    after: $after
                    first: $first
                    before:""
                    estado_Icontains: $estado
                ) {
                    edges {
                        cursor
                        node {
                           id
                           estado
                            tipoCobertura
                            seguroRc
                            revisado
                            tipoPago
                            fecha
                            precioHoraExtra
                            numContrato
                            fechaSalida
                            total
                            fechaRegreso
                            tarifaDiaria
                            tarifaExtra
                            dias
                            tanque
                            deuda
                            depositoEfectivo
                            checkin
                            credito
                            comentarios
                            canal
                            agente {
                                id
                                username
                                firstName
                                lastName
                                email
                            }
                            cliente {
                                id
                                nombre
                                apellidos
                                direccion
                                telefonoEmergencia
                                telefono
                                email
                                edad
                                licencia {
                                    numero
                                    vencimiento
                                    estado
                                }
                            }
                            vehiculo {
                                id
                                placa
                                noEcon
                                color
                                capacidadTanke
                                version{
                                  name
                                  modelo{
                                    name
                                    marca{
                                      name
                                    }
                                  }
                                }
                                categoria {
                                    id
                                    name
                                    tradicionalAlta
                                    totalAlta
                                    tradicionalBaja
                                    totalBaja
                                }
                            }
                            sucursal {
                                id
                                name
                            }
                            conductor {
                                id
                                nombre
                                apellidos
                                edad
                                licencia {
                                    id
                                    numero
                                    vencimiento
                                    permanente
                                    estado
                                }
                                cliente {
                                    id
                                    nombre
                                    apellidos
                                    telefono
                                    email
                                    edad
                                    isActive
                                    direccion
                                    postal
                                    telefonoEmergencia
                                }
                            }
                            tarjeta {
                                id
                                numero
                                vencimiento
                                autorizacion
                            }
                        }
                    }
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                }
            }`,
      variables: obj
    }
  );
};
export const getContractsPrev = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query SearchContratos(
                $name: String!
                $before: String!
                $first: Int!
                $estado:String!
            ) {
                searchContracts(
                    name:$name
                    before: $before
                    after:""
                    first: $first
                    estado_Icontains: $estado
                ) {
                    edges {
                        cursor
                        node {
                            id
                            estado
                            tipoCobertura
                            seguroRc
                            revisado
                            tipoPago
                            fecha
                            precioHoraExtra
                            numContrato
                            fechaSalida
                            total
                            fechaRegreso
                            tarifaDiaria
                            tarifaExtra
                            dias
                            tanque
                            deuda
                            depositoEfectivo
                            checkin
                            credito
                            comentarios
                            canal
                            agente {
                                id
                                username
                                firstName
                                lastName
                                email
                            }
                            cliente {
                                id
                                nombre
                                apellidos
                                direccion
                                telefonoEmergencia
                                telefono
                                email
                                edad
                                licencia {
                                    numero
                                    vencimiento
                                    estado
                                }
                            }
                            vehiculo {
                                id
                                placa
                                noEcon
                                color
                                capacidadTanke
                                version {
                                    name
                                    modelo {
                                        name
                                        marca {
                                            name
                                        }
                                    }
                                }
                                categoria {
                                    id
                                    name
                                    tradicionalAlta
                                    totalAlta
                                    tradicionalBaja
                                    totalBaja
                                }
                            }
                            sucursal {
                                id
                                name
                            }
                            conductor {
                                id
                                nombre
                                apellidos
                                edad
                                licencia {
                                    id
                                    numero
                                    vencimiento
                                    permanente
                                    estado
                                }
                                cliente {
                                    id
                                    nombre
                                    apellidos
                                    telefono
                                    email
                                    edad
                                    isActive
                                    direccion
                                    postal
                                    telefonoEmergencia
                                }
                            }
                            tarjeta {
                                id
                                numero
                                vencimiento
                                autorizacion
                            }
                        }
                    }
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                }
            }`,
      variables: obj
    }
  );
};


export const getDisponiblesCar = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query AllDisponibles(
                $categoria: Int!
                $fechaSalida:DateTime!
                $fechaRegreso:DateTime!
            ) {
                vehiculosDisponibles(
                    categoria: $categoria
                    fechaSalida:$fechaSalida
                    fechaRegreso:$fechaRegreso
                    after:""
                    before:""
                    first:100
                ) {
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                    edges {
                        cursor
                        node {
                            id
                            noEcon
                            categoria {
                                id
                                name
                                tradicionalAlta
                                totalAlta
                                tradicionalBaja
                                totalBaja
                                isActive
                                fecha
                                image
                                iconoWhite
                                iconoBlack
                                maletas
                                maletasSm
                                personas
                                transmision
                                lugar
                            }
                            version {
                                id
                                name
                                isActive
                                modelo {
                                  id
                                  name
                                  marca {
                                    id
                                    name
                                  }
                                }
                            }
                            anno
                            transmision
                            vin
                            titular {
                                id
                                name
                                isActive
                            }
                            aseguradora {
                                id
                                name
                                isActive
                            }
                            placa
                            estado {
                                id
                                name
                                abreviatura
                                isActive
                            }
                            isActive
                            numeroSerie
                            color
                            capacidadTanke
                            fechaAlta
                            sucursal {
                                id
                                name
                                phone
                                contactPhone
                                address
                                postalCod
                                openHour
                                closeHour
                                isActive
                            }
                            estatus
                            fechaCredito
                            fechaCirculacion
                            fechaPoliza
                            files
                        }
                    }
                }
            }`,
      variables: obj
    }
  );
};
export const getDisponiblesCarDisponibilidad = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query AllDisponibles(
                $before: String!
                $after: String!
                $first: Int!
                $categoria: Int!
                $fechaSalida:DateTime!
                $fechaRegreso:DateTime!
            ) {
                vehiculosDisponiblesDisponibilidad(
                    categoria: $categoria
                    before: $before
                    after: $after
                    first: $first
                    fechaSalida:$fechaSalida
                    fechaRegreso:$fechaRegreso
                ) {
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                    edges {
                        cursor
                        node {
                            id
                            noEcon
                            categoria {
                                id
                                name
                                tradicionalAlta
                                totalAlta
                                tradicionalBaja
                                totalBaja
                                isActive
                                fecha
                                image
                                iconoWhite
                                iconoBlack
                                maletas
                                maletasSm
                                personas
                                transmision
                                lugar
                            }
                            version {
                                id
                                name
                                isActive
                                modelo {
                                  id
                                  name
                                  marca {
                                    id
                                    name
                                  }
                                }
                            }
                            anno
                            transmision
                            vin
                            titular {
                                id
                                name
                                isActive
                            }
                            aseguradora {
                                id
                                name
                                isActive
                            }
                            placa
                            estado {
                                id
                                name
                                abreviatura
                                isActive
                            }
                            isActive
                            numeroSerie
                            color
                            capacidadTanke
                            fechaAlta
                            sucursal {
                                id
                                name
                                phone
                                contactPhone
                                address
                                postalCod
                                openHour
                                closeHour
                                isActive
                            }
                            estatus
                            fechaCredito
                            fechaCirculacion
                            fechaPoliza
                            files
                        }
                    }
                }
            }`,
      variables: obj
    }
  );
};
export const getDisponiblesCarDisponibilidadPrev = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query AllDisponibles(
                $before: String!
                $first: Int!
                $categoria: Int!
                $fechaSalida:DateTime!
                $fechaRegreso:DateTime!
            ) {
                vehiculosDisponiblesDisponibilidad(
                    categoria: $categoria
                    before: $before
                    after:""
                    first: $first
                    fechaSalida:$fechaSalida
                    fechaRegreso:$fechaRegreso
                ) {
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                    edges {
                        cursor
                        node {
                            id
                            noEcon
                            categoria {
                                id
                                name
                                tradicionalAlta
                                totalAlta
                                tradicionalBaja
                                totalBaja
                                isActive
                                fecha
                                image
                                iconoWhite
                                iconoBlack
                                maletas
                                maletasSm
                                personas
                                transmision
                                lugar
                            }
                            version {
                                id
                                name
                                isActive
                                modelo {
                                  id
                                  name
                                  marca {
                                    id
                                    name
                                  }
                                }
                            }
                            anno
                            transmision
                            vin
                            titular {
                                id
                                name
                                isActive
                            }
                            aseguradora {
                                id
                                name
                                isActive
                            }
                            placa
                            estado {
                                id
                                name
                                abreviatura
                                isActive
                            }
                            isActive
                            numeroSerie
                            color
                            capacidadTanke
                            fechaAlta
                            sucursal {
                                id
                                name
                                phone
                                contactPhone
                                address
                                postalCod
                                openHour
                                closeHour
                                isActive
                            }
                            estatus
                            fechaCredito
                            fechaCirculacion
                            fechaPoliza
                            files
                        }
                    }
                }
            }`,
      variables: obj
    }
  );
};
export const getDisponiblesCarDisponibilidadNext = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `query AllDisponibles(
                $after:String!
                $first:Int!
                $categoria: Int!
                $fechaSalida:DateTime!
                $fechaRegreso:DateTime!
            ) {
                vehiculosDisponiblesDisponibilidad(
                    categoria: $categoria
                    after: $after
                    before:""
                    first: $first
                    fechaSalida:$fechaSalida
                    fechaRegreso:$fechaRegreso
                ) {
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                    edges {
                        cursor
                        node {
                            id
                            noEcon
                            categoria {
                                id
                                name
                                tradicionalAlta
                                totalAlta
                                tradicionalBaja
                                totalBaja
                                isActive
                                fecha
                                image
                                iconoWhite
                                iconoBlack
                                maletas
                                maletasSm
                                personas
                                transmision
                                lugar
                            }
                            version {
                                id
                                name
                                isActive
                                modelo {
                                  id
                                  name
                                  marca {
                                    id
                                    name
                                  }
                                }
                            }
                            anno
                            transmision
                            vin
                            titular {
                                id
                                name
                                isActive
                            }
                            aseguradora {
                                id
                                name
                                isActive
                            }
                            placa
                            estado {
                                id
                                name
                                abreviatura
                                isActive
                            }
                            isActive
                            numeroSerie
                            color
                            capacidadTanke
                            fechaAlta
                            sucursal {
                                id
                                name
                                phone
                                contactPhone
                                address
                                postalCod
                                openHour
                                closeHour
                                isActive
                            }
                            estatus
                            fechaCredito
                            fechaCirculacion
                            fechaPoliza
                            files
                        }
                    }
                }
            }`,
      variables: obj
    }
  );
};
export const getCoberturasTotal = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
      query CoberturasPerTarifaTotal {
          coberturasPerTarifaTotal(name: "") {
              id
              cobertura
              isActive
          }
      }`
    }
  );
};
export const getCoberturasTradicional = () => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
      query CoberturasPerTarifaTradicional {
          coberturasPerTarifaTradicional(name: "") {
              id
              cobertura
              isActive
          }
      }`
    }
  );
};


export const getCategoriasDisponibles = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
      query CategoriasDisponiblesAdmin($fechaRenta:DateTime!, $fechaRegreso:DateTime!) {
          categoriasDisponiblesAdmin(fechaSalida: $fechaRenta, fechaRegreso: $fechaRegreso) {
              id
              name
              tradicionalAlta
              totalAlta
              tradicionalBaja
              totalBaja
              isActive
              fecha
              image
              iconoWhite
              iconoBlack
              maletas
              maletasSm
              personas
              transmision
              lugar
          }
      }`,
      variables: obj
    }
  );
};
export const checkReserve = (obj) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
      query VerificarCategoria(
        $fechaSalida:DateTime!
        $fechaRegreso:DateTime!
        $categoria:ID!
        $reserva:ID!
    ) {
        verificarCategoria(
            fechaSalida: $fechaSalida
            fechaRegreso: $fechaRegreso
            categoria: $categoria
            reserva: $reserva
        )
    }
    `,
      variables: obj
    }
  );
};
export const getCategory = (id) => {
  return axios.post(process.env.VUE_APP_URL_SERVICES_GRAPHQL,
    {
      query: `
      query CategoriaPerId($id:ID!) {
            categoriaPerId(id: $id) {
                id
                name
                tradicionalAlta
                totalAlta
                tradicionalBaja
                totalBaja
                isActive
                visible
                deleted
                fecha
                image
                iconoWhite
                iconoBlack
                maletas
                maletasSm
                personas
                transmision
                lugar
                descripcion
            }
        }`,
      variables: { id: id }
    }
  );
};

